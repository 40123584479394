// This allows us to load Angular components on legacy server-side pages.
// This mostly import the main AppModule and disable the Angular Router to
// redirect all navigations to the server side
import { APP_BASE_HREF } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DISABLE_ERROR_TRACKING } from '@freelancer/tracking';
import { AppCommonModule } from '../app/app-common.module';
import { CompatRedirectGuard } from './compat-redirect-guard.service';
import { CompatComponent } from './compat.component';

@Component({
  template: ` <!-- this is empty --> `,
})
export class EmptyComponent {}

// /!\ DO ADD ANYTHING IN THERE WITHOUT TALKING TO FRONTEND INFRA FIRST /!\
@NgModule({
  imports: [
    AppCommonModule,
    RouterModule.forRoot([
      {
        path: '**',
        component: EmptyComponent,
        canActivate: [CompatRedirectGuard],
      },
    ]),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: false }),
  ],
  declarations: [EmptyComponent, CompatComponent],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: DISABLE_ERROR_TRACKING, useValue: true },
  ],
  bootstrap: [CompatComponent],
})
export class CompatModule {}
