import type {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { NEVER } from 'rxjs';

let initialRouteLoaded = false;

export const CompatRedirectGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): ReturnType<CanActivateFn> => {
  if (!initialRouteLoaded) {
    initialRouteLoaded = true;
    return true;
  }
  if (window.location.hostname === 'localhost') {
    window.location.assign(`https://www.syd1.fln-dev.net${state.url}`);
  } else {
    window.location.assign(state.url);
  }
  return NEVER;
};
