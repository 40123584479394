import { Location } from '@angular/common';
import { enableProdMode } from '@angular/core';
/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 * (needed here because we don't load webapp polyfills in compat)
 */
import '@angular/localize/init';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { CompatModule } from './compat/compat.module';
import { environment } from './environments/environment';

// FIXME: T267853 - remove that when URL consistency has been fixed in GAF
(Location as any).stripTrailingSlash = function _stripTrailingSlash(
  url: string,
): string {
  return url;
};

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(CompatModule, {
  preserveWhitespaces: false,
});
