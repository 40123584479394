// This allows us to load Angular components on legacy server-side pages.
import type { OnInit, Type } from '@angular/core';
import {
  createNgModule,
  Component,
  HostBinding,
  Injector,
  SkipSelf,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import type { LazyComponent } from './components';
import { components } from './components';

interface BootstrappedComponent {
  index: number | undefined;
  componentType: Type<any>;
  moduleType: Type<any>;
}

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'webapp-compat',
  template: `
    <ng-container #entryPoint></ng-container>
    <router-outlet></router-outlet>
  `,
})
export class CompatComponent implements OnInit {
  @ViewChild('entryPoint', { read: ViewContainerRef, static: true })
  entryPoint: ViewContainerRef;

  @HostBinding('class')
  readonly class = 'WebappCompatWrapper';

  constructor(@SkipSelf() private injector: Injector) {}

  ngOnInit(): void {
    // Only bootstrap the components on the page.
    const componentsToCreate = components.filter(
      ({ selector }) => document.getElementsByTagName(selector)[0],
    );

    // We need to bootstrap all the components first before we load them onto the page.
    this.bootstrapComponents(componentsToCreate).then(loadedComponents =>
      loadedComponents.forEach(componentItem =>
        this.loadComponent(componentItem),
      ),
    );
  }

  /**
   * Asynchronously bootstraps a lazy-loaded Angular component.
   *
   * @param {LazyComponent} component - An object representing the lazy-loaded component to be loaded.
   * @returns {Promise<readonly BootstrappedComponent[]>} A promise that resolves once all the component has been bootstrapped.
   */
  private bootstrapComponents(
    componentsToBootstrap: readonly LazyComponent[],
  ): Promise<readonly BootstrappedComponent[]> {
    return Promise.all(
      componentsToBootstrap
        // Load the module and component
        .map(async ({ index, loadComponent, loadModule }) => ({
          index,
          componentType: await loadComponent(),
          moduleType: await loadModule(),
        })),
    );
  }

  /**
   * Loads a bootstrapped lazy-loaded Angular component onto the page.
   *
   * @param {BootstrappedComponent} - An object representing the bootstrapped lazy-loaded component to be loaded.
   * @returns {void}
   */
  private loadComponent({
    index,
    componentType,
    moduleType,
  }: BootstrappedComponent): void {
    // Create the component and add it to the entry point
    this.entryPoint.createComponent(componentType, {
      index,
      ngModuleRef: createNgModule(moduleType, this.injector),
      injector: this.injector,
    });
  }
}
